/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PaypalExpressContext } from '../../context/PaypalExpress';
import { PaypalConfigsType } from '../../type/Paypal.type';
import { filterEmptyKeys } from '../../util/Utils';
import PaypalMessages from './PaypalMessages.component';

/** @namespace Scandiweb/PaypalExpress/Component/PaypalMessages/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    paypalConfigs: state.ConfigReducer?.paypalConfigs
});

/** @namespace Scandiweb/PaypalExpress/Component/PaypalMessages/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/PaypalExpress/Component/PaypalMessages/Container */
export class PaypalMessagesContainer extends PureComponent {
    static propTypes = {
        page: PropTypes.string.isRequired,
        paypalConfigs: PaypalConfigsType
    };

    static defaultProps = {
        paypalConfigs: {}
    };

    static contextType = PaypalExpressContext;

    containerFunctions = {};

    componentDidMount() {
        this.initMessages();
        this.prevContext = this.context;
    }

    componentDidUpdate() {
        const {
            paypal: prevPaypal
        } = this.prevContext;
        const {
            paypal
        } = this.context;

        if (paypal !== prevPaypal) {
            this.initMessages();
            this.prevContext = this.context;
        }
    }

    initMessages() {
        const { page } = this.props;
        const { paypal } = this.context;
        const bannerConfig = this.getBannerConfigs();
        const { isEnabled } = bannerConfig;

        // vvv check if paypal sdk is ready or if the banner is disabled
        if (!paypal || !isEnabled) {
            return;
        }

        // eslint-disable-next-line new-cap
        paypal.Messages(bannerConfig)
            .render(`#${page}-messages`);
    }

    getBannerConfigs() {
        const {
            page,
            paypalConfigs: {
                paypalBannersConfig
            } = {}
        } = this.props;

        if (!paypalBannersConfig) {
            return {};
        }

        // vvv filter keys with empty values ( e.g. null ) because style property for messages doesn't accept it
        const filteredConfigs = filterEmptyKeys(paypalBannersConfig[page]);

        return filteredConfigs;
    }

    getIsEnabled() {
        const { isEnabled } = this.getBannerConfigs();

        return isEnabled;
    }

    containerProps() {
        const { page } = this.props;

        return {
            page,
            isEnabled: this.getIsEnabled()
        };
    }

    render() {
        return (
            <PaypalMessages
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalMessagesContainer);
