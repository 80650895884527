/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// vvv Placement for PayPal components ( messages and smart buttons )
export const PAYPAL_PLACEMENT_HOME_PAGE = 'home';
export const PAYPAL_PLACEMENT_CART_PAGE = 'cart';
export const PAYPAL_PLACEMENT_MINI_CART = 'miniCart';
export const PAYPAL_PLACEMENT_PRODUCT_PAGE = 'product';
export const PAYPAL_PLACEMENT_CHECKOUT_PAGE = 'checkout';
export const PAYPAL_PLACEMENT_CATEGORY_PAGE = 'category';

// vvv Payment Method Codes
export const PAYPAL_EXPRESS_PAYMENT_METHOD_CODE = 'paypal_express';
export const PAYPAL_PAYLATER_PAYMENT_METHOD_CODE = 'paypal_express_bml';
export const PAYPAL_BILLING_AGREEMENT_PAYMENT_METHOD_CODE = 'paypal_billing_agreement';
