/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const SelectOptionType = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string
});

export const SelectOptionsType = PropTypes.arrayOf(SelectOptionType);

export const PaypalBillingOrderType = PropTypes.shape({
    order_increment_id: PropTypes.string,
    created_at: PropTypes.string,
    shipping_address: PropTypes.string,
    order_total: PropTypes.string,
    status_label: PropTypes.string,
    view_url: PropTypes.string,
    orderId: PropTypes.string
});

export const PaypalBillingOrdersType = PropTypes.arrayOf(PaypalBillingOrderType);

export const PaypalBillingAgreementType = PropTypes.shape({
    id: PropTypes.string,
    reference_id: PropTypes.string,
    status: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    payment_method_label: PropTypes.string,
    edit_url: PropTypes.string
});

export const PaypalBillingAgreementsType = PropTypes.arrayOf(PaypalBillingAgreementType);

export const PaypalShippingRateType = PropTypes.shape({
    shippingRateValue: PropTypes.string,
    shippingRateOption: PropTypes.string
});

export const PaypalShippingRateGroupsType = PropTypes.arrayOf(PropTypes.shape({
    carrierName: PropTypes.string,
    shippingRates: PropTypes.arrayOf(PaypalShippingRateType)
}));

export const PaypalSmartButtonStyleConfigType = PropTypes.shape({
    layout: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    shape: PropTypes.string,
    label: PropTypes.string
});

export const PaypalSmartButtonStyleConfigsType = PropTypes.shape({
    product: PaypalSmartButtonStyleConfigType,
    checkout: PaypalSmartButtonStyleConfigType,
    cart: PaypalSmartButtonStyleConfigType,
    miniCart: PaypalSmartButtonStyleConfigType
});

export const PaypalSmartButtonsConfigType = PropTypes.shape({
    styleConfigs: PaypalSmartButtonStyleConfigsType
});

export const PaypalBannerTextStyleConfigType = PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string
});

export const PaypalBannerLogoStyleConfigType = PropTypes.shape({
    type: PropTypes.string,
    position: PropTypes.string
});

export const PaypalBannerStyleConfigType = PropTypes.shape({
    layout: PropTypes.string,
    logo: PaypalBannerLogoStyleConfigType,
    text: PaypalBannerTextStyleConfigType,
    color: PropTypes.string,
    ratio: PropTypes.string
});

export const PaypalBannerConfigType = PropTypes.shape({
    display: PropTypes.bool,
    position: PropTypes.string,
    placement: PropTypes.string,
    style: PaypalBannerStyleConfigType
});

export const PaypalBannersConfigType = PropTypes.shape({
    home: PaypalBannerConfigType,
    category: PaypalBannerConfigType,
    product: PaypalBannerConfigType,
    cart: PaypalBannerConfigType,
    payment: PaypalBannerConfigType
});

export const PaypalConfigsType = PropTypes.shape({
    sdkUrl: PropTypes.string,
    isVisibleOnProductPage: PropTypes.bool,
    isGuestCheckoutAllowed: PropTypes.bool,
    paymentWhatIs: PropTypes.string,
    paymentAcceptanceMarkUrl: PropTypes.string,
    paymentAcceptanceMarkImageUrl: PropTypes.string,
    paypalBannersConfig: PaypalBannersConfigType,
    paypalSmartButtonsConfig: PaypalSmartButtonsConfigType
});
