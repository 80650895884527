/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Scandiweb/PaypalExpress/Util/Utils/convertToObjectFromEntries */
export const convertToObjectFromEntries = (entries) => entries.reduce((acc, [k, v]) => {
    acc[k] = v;
    return acc;
}, {});

/** @namespace Scandiweb/PaypalExpress/Util/Utils/filterEmptyKeys */
export const filterEmptyKeys = (obj) => Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce(
        (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? filterEmptyKeys(v) : v }),
        {}
    );

/** @namespace Scandiweb/PaypalExpress/Util/Utils/stripHtml */
export const stripHtml = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    const text = tmp.textContent || tmp.innerText || '';
    tmp.remove();

    return text;
};
