/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './PaypalMessages.style';

/** @namespace Scandiweb/PaypalExpress/Component/PaypalMessages/Component */
export class PaypalMessagesComponent extends PureComponent {
    static propTypes = {
        page: PropTypes.string.isRequired,
        isEnabled: PropTypes.bool.isRequired
    };

    render() {
        const { page, isEnabled } = this.props;

        if (!isEnabled) {
            return null;
        }

        return (
          <div
            id={ `${page}-messages` }
            block="PaypalMessages"
          />
        );
    }
}

export default PaypalMessagesComponent;
